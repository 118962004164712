.order_countries_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--format-lg-gap);
}

.order_countries_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--format-lg-gap);
  margin: 0;
  padding: 0;
  list-style-type: none;
}