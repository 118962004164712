.package_control_block {
  display: flex;
  gap: var(--format-lg-gap);
  align-items: center;
}

.package_control_row {
  align-items: center;
}

.package_addition_label {
  width: 139px;
  padding: var(--format-md-gap) 0;//гэп в базе был
}

.package_addition_title {

}