.radio_button_block {
  display: flex;
  gap: 8px;
}

.radio_button_label {
  display: inline-block;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: var(--font-size-content);
  color: var(--color-black);
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    height: 19px;
    width: 19px;
    background-color: transparent;
    border: 2px solid var(--color-black-40);
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);

    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--color-brand-blue);
    display: none;
  }
}

.radio_button_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked + .radio_button_label::before {
    border: 2px solid var(--color-brand-blue);
  }

  &:checked + .radio_button_label::after {
    display: block;
  }
}