.button {
  font-family: var(--font-family);
  font-size: var(--font-size-content);
  font-weight: var(--font-weight-regular);
  border-radius: var(--format-small-border-radius);
  cursor: pointer;
  padding: var(--format-md-padding) var(--format-md-padding);
  width: 175px;
  height: min-content;
  border: 1px solid transparent;

  &[data-size='sm'] {
    padding: var(--format-sm-padding) var(--format-md-padding);
    width: max-content;
    font-size: 12px;
  }

  &[data-size='md'] {
    padding: var(--format-md-padding) var(--format-lg-padding);
    width: max-content;
  }

  &[data-size='lg'] {
    width: 100%;
  }

  &[data-variant='blue'] {
    color: var(--color-white);
    background-color: var(--color-secondary-btn);
    border: 0;

    &:hover {
      background-color:var(--color-brand-blue);
    }

    &:disabled {
      background-color: var(--color-grey-semi);
      color: var(--color-black-40);
    }
  }

  &[data-variant='white'] {
    color: var(--color-black);
    background-color: transparent;
    border: 1px solid var(--color-secondary-btn);

    &:hover {
      background-color: var(--color-primary-blue);
    }
  }

  &[data-variant='red'] {
    color: var(--color-secondary-red);
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: var(--color-black-5);
    }
  }

  &:disabled {
    background-color: var(--color-grey-semi);
    color: var(--color-black-40);
  }
}
