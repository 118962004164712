.date_filter_block {
  display: flex;
  align-items: center;
  gap: var(--format-md-padding);
  z-index: 2;
  height: min-content;

  &:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url('../../Images/svg/date-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.date_input {
  position: relative;
  box-sizing: border-box;
}

.styled_data_picker {
  padding: var(--format-md-padding);
  display: block;
  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-md-border-radius);
  background-color: transparent;
  width: 210px;
  z-index: 2;

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);

  color: var(--color-black);
  box-sizing: border-box;
  cursor: pointer;
}