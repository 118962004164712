.heading {
  display: flex;
  gap: 40px;
  margin-bottom: 16px;
}

.return_button {
  width: 43px;
  height: 43px;
  background-color: transparent;
  border: 0;

  & span {
    width: 14px;
    height: 24px;

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.store {
  width: 24px;
  height: 24px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  & a {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}

.title {
  margin-bottom: 0;
  line-height: 120%;
}

.date_selector {
  margin-left: auto;
}