.delay_attention_modal_wrapper {
  gap: 32px;
  align-items: center;
}

.delay_attention_modal_title {
  margin-bottom: 0;
}

.delay_attention_modal_text {
  text-align: center;
  font-size: 16px;
}

.delay_checkbox_label {
  display: flex;
  align-items: center;
  gap: 12px;
}

.delay_checkbox_span {
  color: var(--color-black);
}

.delay_attention_button_block {
  width: 250px;
}