.details_wrapper {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  //font-size: var(--font-size-title);
  font-size: var(--font-size-large-content);

  color: var(--color-black);

  max-width: 1200px;
  // max-height: 100%;

  position: relative;
}

.details__title_wrapper {
}

.details__title {
}

.details__title_id {
  padding: 0 32px;
}

.details__logo_wrapper {
  margin: 32px 0;
}

.details__logo {
  margin: 0 16px 0 0;
  width: 32px;
  height: 32px;
}

.details__list_info {
  list-style: none;
  margin: 0 0 32px 0;
  padding: 0;
}

.details__list_item {
  display: flex;
}

.details__item_title {
  width: 200px;
  margin: 0 32px 0 0;
}

.details__item_info {
  font-weight: var(--font-weight-regular);
}

.details__campaign_dates_wrapper {
  position: relative;
  margin: 0 0 32px 0;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../../../../../../Images/svg/date-icon.svg");
    width: 32px;
    height: 32px;
    top: -5px;
  }
}

.details__campaign_dates {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large-content);
  margin: 0 0 0 48px;
}

.details__campaign_date {
  padding: 0 16px;
}

.details__table_wrapper {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  table {
    width: 100%;
    border-collapse: collapse;

    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-large-content);
    color: var(--color-black);
  }

  th {
    text-align: start;
    min-width: 116px;
    font-weight: var(--font-weight-regular);
    color: var(--color-black-60);
    background-color: var(--color-primary-light);
    padding: 8px 0 8px 8px;
    border-bottom: 0.8px solid var(--color-grey-semi);

    &:first-child {
      width: 116px;
    }
  }

  td {
    min-width: 116px;
    padding: 16px 0 16px 8px;

    &:nth-child(2) {
      padding-right: 8px;
    }
  }
}

.sticky_column {
  position: sticky;
  z-index: 1;
}

.sticky_column_country {
  @extend .sticky_column;
  left: 0;
}

.sticky_column_keys {
  @extend .sticky_column;
  left: 116px;
}

.details__table_total_cell {
  font-weight: var(--font-weight-bold);
  background-color: var(--color-grey-light);
}

.details__table_regular_cell {
  background-color: var(--color-white);
}

.close_button {
  position: absolute;
  top: 45px;
  right: 45px;
  padding: 0;
  margin: 0;
  background: none;
}

.loader_wrapper {
  position: relative;
  height: 64px;
}
