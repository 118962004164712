.auth_input_label {
  width: 100%;
  padding: var(--format-md-padding);
  box-sizing: border-box;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-md-border-radius);
  display: inline-flex;
  gap: var(--format-sm-gap);
  position: relative;

  &:focus-within {
    border: 1px solid var(--color-secondary-btn);
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;

    background-repeat: no-repeat;
    background-size: contain;
  }

  &[data-field='email'] {
    &::before {
      background-image: url('../../Images/svg/email-icon.svg');
      opacity: 0.7;
    }
  }

  &[data-field='password'] {
    &::before {
      background-image: url('../../Images/svg/password-icon.svg');
    }
  }

  &[data-in-error='true'] {
    border: 1px solid var(--color-secondary-red);
  }
}

.auth_input {
  border: 0;
  background-color: transparent;
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-black);

  width: calc(100% - 34px);

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-grey-semi);
  }
}

.auth_input_error_text {
  display: none;
  position: absolute;
  width: max-content;

  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);

  color: var(--color-secondary-red);

  &[data-in-error='true'] {
    display: block;
  }
}