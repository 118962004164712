.styled_statistic_button {
  width: 24px;
  height: 20px;
  object-fit: contain;
  background-color: transparent;
  border: 0;
  padding: 0;

  cursor: pointer;

  color: var(--color-black-40);

  &:hover {
    color: var(--color-black-60);
  }
}