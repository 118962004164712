.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
}

.list_button {
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.prev {
  background-image: url('../../../../../../Images/svg/arrows/arrow-left.svg');
}

.next {
  background-image: url('../../../../../../Images/svg/arrows/arrow-right.svg');
}

.list {
  flex-grow: 1;
  display: flex;
  gap: 22px;
  width: 100%;
  overflow: auto;

  scrollbar-width: none;
}

.item {
  font-family: var(--font-family);
  line-height: 140%;
  background-color: transparent;
  color: var(--color-black-60);
  padding: 0;

  position: relative;

  & span {
    display: block;
    width: max-content;
  }
}

.active {
  color: #0038B0;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--color-brand-chambray);
  }
}