.copy_button {
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--color-grey);
  background-color: transparent;
  border: 0;

  &:hover {
    color: var(--color-black-60);
  }
}