.submit_campaign_modal {
  max-width: 512px;
}

.submit_campaign_modal_title {
  text-align: center;
}

.submit_campaign_modal_text_block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto 64px;
}

.submit_campaign_modal_text {
  text-align: center;
  font-size: var(--font-size-large-content);
}