.input_block {
  padding: var(--format-md-padding);
  box-sizing: border-box;
  display: flex;
  gap: var(--format-sm-gap);
  align-items: center;
  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-md-border-radius);

  &[data-size="lg"] {
    width: 765px;
  }

  &[data-size="md"] {
    width: 397px;
  }

  &:focus-within {
    border: 1px solid var(--color-grey);
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('../../Images/svg/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.input {
  width: 100%;
  border: 0;
  background-color: transparent;
  font-family: var(--font-family-semi);
  font-size: var(--font-size-content);
  font-weight: var(--font-weight-regular);

  &:focus-visible {
    outline: 0;
    border: 0;
  }
}