.select_block {
  position: relative;
  width: 418px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    right: 1px;
    pointer-events: none;

    background-image: url('../../../Images/svg/arrows/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 50% 50%;
    background-color: var(--color-white);

    padding: 12px;
  }

  &[data-list-is-open='true'] {
    &::after {
      background-image: url('../../../Images/svg/arrows/arrow-up.svg');
    }
  }
}

.option_block {
  display: none;
  overflow: hidden;

  position: absolute;
  top: calc(100% - 1px);
  z-index: 5;

  width: 100%;
  background-color: var(--color-white);

  border-right: 1px solid var(--color-grey);
  border-left: 1px solid var(--color-grey);
  border-bottom: 1px solid var(--color-grey);
  border-radius: 0 0 var(--format-md-border-radius) var(--format-md-border-radius);

  &[data-is-open='true'] {
    display: block;
  }

  &[data-is-focused='true'] {
    outline: 1.5px solid var(--color-brand-blue);
    border: 1px solid transparent;
  }
}

.input_field {
  width: 100%;
  box-sizing: border-box;
  padding: var(--format-md-padding);
  background-color: transparent;
  border-radius: var(--format-md-border-radius);
  border: 1px solid var(--color-grey-semi);

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-black);

  &:focus-visible {
    outline: 1.5px solid var(--color-brand-blue);
    border: 1px solid transparent;
  }

  &:disabled {
    color: var(--color-grey);
  }

  &[data-is-open='true'] {
    border-radius: var(--format-md-border-radius) var(--format-md-border-radius) 0 0;
  }
}

.options_list {
  padding: 0;
  margin: 0;

  list-style-type: none;
}

.option_item {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);

  padding: var(--format-sm-padding) var(--format-md-padding);

  &:hover {
    background-color: var(--color-grey-lightest);
  }
}