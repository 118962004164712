.checkbox_button_label {
  display: block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid var(--color-brand-blue);
  border-radius: 4px;

  position: relative;
}

.checkbox_button_mark {
  width: 11px;
  height: 8px;

  position: absolute;
  color: var(--color-brand-blue);
  left: 3px;
  top: 5px;
  display: none;

  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.checkbox_button_block {
  &[data-is-disabled='true'] {
    .checkbox_button_label {
      border: 2px solid var(--color-black-40);
    }
    .checkbox_button_mark {
      color: var(--color-black-80);
    }
  }
}

.checkbox_button_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked + .checkbox_button_label {
    border: 2px solid var(--color-brand-blue);
  }

  &:checked + .checkbox_button_label .checkbox_button_mark {
    display: block;
  }

  &:disabled + .checkbox_button_label {
    border: 2px solid var(--color-black-40);
  }

  &:disabled + .checkbox_button_label .checkbox_button_mark {
    color: var(--color-black-80);
  }
}
