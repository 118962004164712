.period_button_block {
  display: flex;
  width: min-content;
  align-items: center;
  gap: 18px;
}

.period_statistic_button {
  height: 41px;

  & span {
    width: 20px;
    height: 15px;

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}