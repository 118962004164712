.selector {
  display: flex;
  padding: 4px;
  gap: 6px;

  background-color: #ECF1FC;
  border-radius: 100px;
  width: min-content;
}

.button {
  background-color: transparent;
  border-radius: 100px;
  border: 0;
  width: max-content;
  padding: 8px 16px;

  font-family: var(--font-family);
  font-size: var(--font-size-content);
  color: #000000;

  &:disabled {
    background-color: #ffffff;
    color: #0038B0;
  }
}