.table {
  width: 100%;
}

.thead {
  position: sticky;
  top: -1px;

  background-color: #F7F9FB;

  & tr {
    border: 0;
  }
}

.tfoot {
  position: sticky;
  bottom: -1px;
}

.tbody {
  & tr {
    background-color: var(--color-white);

    &:nth-child(even) {
      background-color: var(--color-primary-light);
    }
  }
}

.country {
  &:hover {
    cursor: pointer;
    background-color: var(--color-primary-blue);
  }
}

.th {
  padding: 10px;
  background-color: var(--color-primary-light);
  color: var(--color-black-60);
  border-bottom: 1px solid var(--color-grey-semi);

  width: min-content;

  &[data-align='left'] {
    text-align: left;
  }

  &[data-align='right'] {
    text-align: right;
  }

  &:first-child {
    width: 100%;
  }
}

.td {
  padding: 16px 10px;
  text-align: center;
  color: var(--color-black);

  &[data-align='left'] {
    text-align: left;
  }

  &[data-align='right'] {
    text-align: right;
  }

  &:first-child {
    width: 100%;
  }
}

.full {
  width: unset !important;
}

.sum {
  background-color: #C6D6F8;
  font-weight: var(--font-weight-bold);
}

.app_row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  & a {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}

.detail_button {
  width: 25px;
  height: 25px;
  line-height: 25px;

  background-color: transparent;
  border: 0;
  padding: 0;

  background-image: url("../../../../../../Images/svg/arrows/arrow-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
