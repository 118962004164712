.total_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.total_block {
  display: flex;
  gap: 64px;
}

.total_item {
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: 142px;
}

.total_value_span { //basicElements span
  font-size: 24px;
}

.total_value { //basicElements span
  color: var(--color-brand-blue);
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}