.clear_button {
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;

  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  color: var(--color-grey);

  background-color: var(--color-white);

  &:hover {
    color: var(--color-black);
  }

  &[data-type='select'] {
    right: 32px;
  }
}