.basic__page_wrapper {
  padding: var(--format-lg-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: var(--format-lg-gap);
}

.basic__page_title {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  line-height: var(--font-line-height);
  color: var(--color-brand-blue);
}

.basic__content_text {
  font-family: var(--font-family);
  font-size: var(--font-size-content);
  font-weight: var(--font-weight-regular);
}

.basic__small_text {
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}

.basic__table_cell {
  white-space: nowrap;
  font-family: var(--font-family);
  font-size: var(--font-size-content);
  font-weight: var(--font-weight-regular);
}

.basic__empty_table_data {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: var(--font-family);
  font-size: 30px;
  color: var(--color-grey);
  width: max-content;

  transform: translate(-50%, -50%);
}

.basic__red_accent_span { //span
  color: var(--color-secondary-red);
}