.container {
  display: flex;
  gap: 32px;
}

.filter {
  display: flex;
  flex-direction: column;
  width: min-content;
  gap: 8px;

}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 8px;
  min-height: 27px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.item {
  background-color: var(--color-secondary-btn);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}

.title {
  color: var(--color-white);
  align-self: center;
}

.button {
  width: 16px;
  height: 16px;
  color: var(--color-white-40);
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;

  & svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tagTitle {
  color: var(--color-grey);
  font-size: var(--font-size-large-content);
}