.delay_start_body_wrapper {//div
  display: flex;
  gap: var(--format-md-gap);
}

.time_selector_block {
  position: relative;
}

.time_selector_warning {
  position: absolute;
  display: block;

  bottom: -20px;
  left: 0;
  width: max-content;

  font-family: var(--font-family);
  font-size: var(--font-size-small);
  color: var(--color-secondary-red);
}

.time_selector_input {
  background-color: transparent;

  border: 1px solid var(--color-grey-semi);
  border-radius: 4px;
  padding: 4px 7px;
  width: 150px;

  font-family: var(--font-family);
  font-size: var(--font-size-small);
  color: var(--color-black);

  &[data-in-error='true'] {
    border: 1px solid var(--color-secondary-red);

    &::placeholder {
      color: var(--color-secondary-red);
    }
  }

  &:disabled {
    border: 1px solid var(--color-grey-lightest);
    color: var(--color-grey);

    &::placeholder {
      color: var(--color-grey-semi);
    }
  }
}