.store_icon {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;

  &[data-store='apple'] {
    background-image: url('../../../Images/svg/appstore-logo.svg');
  }

  &[data-store='google'] {
    background-image: url('../../../Images/png/playmarket-icon.png');
  }
}