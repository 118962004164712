.modal_wrapper {
  position: absolute;
  padding: 64px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  min-width: 512px;
  max-width: 1044px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: border-box;
  border-radius: var(--format-lg-border-radius);
  max-height: 800px;
}

.modal_wrapper_full_height {
  @extend .modal_wrapper;
  margin: 43px 161px;
  max-height: calc(100vh - 86px);
  max-width: calc(100vw - 322px);
  top: calc(50% - 43px);
  left: calc(50% - 161px);
}

.custom_backdrop {
  backdrop-filter: blur(8px);
}

.modal_title {
  //h3
  margin: 0 0 32px 0;
  padding: 0;

  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  text-align: start;

  color: var(--theme-color-black);
}

.modal_input_block {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  gap: var(--format-lg-gap);

  margin-bottom: 4px;
}

.close_button_block {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 32px;
  right: 36px;
}
