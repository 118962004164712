.help_button_container {
  position: relative;
}

.help_popup {
  position: absolute;
  top: -16px;

  transform: translateY(-100%);

  display: none;

  background-color: var(--color-primary-light);
  padding: var(--format-md-padding);
  border-radius: var(--format-md-border-radius);

  width: max-content;
  max-width: 250px;
  border: 1px solid var(--color-grey-semi);
  z-index: 15;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;

    background-color: var(--color-primary-light);
    border-bottom: 1px solid var(--color-grey-semi);
    border-right: 1px solid var(--color-grey-semi);
    border-radius: 2px;

    position: absolute;
    bottom: 0;
    transform: translateY(50%) rotate(45deg);
  }

  &[data-side='right'] {
    left: -30px;

    &::before {
      left: 31px;
    }
  }

  &[data-side='left'] {
    right: -30px;

    &::before {
      right: 31px;
    }
  }
}

.help_button {
  color: var(--color-grey);
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: var(--color-black-80);
  }

  &:hover + .help_popup {
    display: block;
  }
}

.help_popup_text {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
}