.readable_input_label {
  position: relative;
}

.readable_input_copy_button {
  position: absolute;
  top: 50%;
  right: 16px;

  transform: translateY(-50%);
  background-color: var(--color-white);
}

.readable_input {
  padding: var(--format-md-padding);
  border: 1px solid var(--color-grey-semi);
  background-color: var(--color-white);

  border-radius: var(--format-md-border-radius);
  color: var(--color-black);

  font-family: var(--font-family);
  font-size: var(--font-size-content);
  font-weight: var(--font-weight-regular);

  &[data-size='lg'] {
    width: 100%;
  }

  &[data-size='md'] {
    width: 420px;
  }
}