.keywords_control_block {
  display: flex;
  gap: var(--format-lg-gap);
}

.keywords_addition_row {
  align-items: flex-start;
}

.keywords_addition_label {
  width: 139px;
  padding: var(--format-md-padding) 0;
}

.keywords_addition_title {

}

.keywords_addition_textarea {
  width: 256px;

  max-width: 400px;
  max-height: 400px;

  overflow-y: auto;

  background-color: var(--color-white);
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);

  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-md-border-radius);
  padding: var(--format-md-padding);
}