.wrapper {
  width: 1172px;
  max-width: unset;

  gap: var(--format-lg-gap);
}

.titleWrapper {
  display: flex;
  gap: var(--format-md-gap-gap);
  align-items: center;
}

.title {
  margin: 0;
}

.controlWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 64px;
}

.table {
  width: 100%;
  overflow: auto;
  position: relative;

  min-height: 300px;
}

.totalRow {
  background-color: var(--color-grey-light);
}

.evenRow {
  background-color: var(--color-primary-light);
}

.th {
  width: 120px;
  padding: 8px;
  text-align: left;

  background-color: var(--color-primary-light);
  border-bottom: 1px solid var(--color-grey-semi);
  color: var(--color-black-60);
}

.td {
  width: 120px;
  padding: 16px 8px;
  color: var(--color-black);

  //&[data-role='key'] {
  //  background-color: var(--color-primary-light);
  //  position: sticky;
  //  left: 0;
  //}

  &[data-total='true'] {
    font-weight: var(--font-weight-bold);
  }
}

.emptyText {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: var(--font-family);
  font-size: 30px;
  color: var(--color-grey);
  width: max-content;

  transform: translate(-50%, -50%);
}