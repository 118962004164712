.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.table_block {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 16px;
}

.empty_text {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: var(--font-family);
  font-size: 30px;
  color: var(--color-grey);
  width: max-content;

  transform: translate(-50%, -50%);
}

.export_block {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.countries_selector {
  width: 100%;
  margin-top: 24px;
}