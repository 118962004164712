.attributes_block { //div
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--format-md-gap);
}

.attribute_row { //div
  display: flex;
  gap: var(--format-lg-gap);
  align-items: flex-start;
}

.attribute_row_heading { //div
  display: flex;
  gap: var(--format-md-gap);

  width: 195px;
  justify-content: right;
  align-items: center;
}

.attribute_row_label { //div
  display: flex;
  align-items: center;
  gap: 8px;
}

.attribute_row_title { //span
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-black);

  &[data-is-disabled='true'] {
    color: var(--color-grey);
  }
}

.attribute_body {

}
