:root {
    /* Colors */
    --color-brand-blue: #5063C0;
    --color-brand-purple: #5C6ECA;
    --color-brand-chambray: #39498F;
    --color-brand-hover: #7D8BD5;

    --color-grey: #A7A7A7;
    --color-grey-semi: #D8DBE2;
    --color-grey-lightest: #F0F2F5;
    --color-grey-light: #E1E2E3;

    --color-white: #FFFFFF;
    --color-white-80: rgba(255, 255, 255, 0.8);
    --color-white-60: rgba(255, 255, 255, 0.6);
    --color-white-40: rgba(255, 255, 255, 0.4);

    --color-black: #030C1C;
    --color-black-80: rgba(3, 12, 28, 0.8);
    --color-black-60: rgba(3, 12, 28, 0.6);
    --color-black-40: rgba(3, 12, 28, 0.4);
    --color-black-20: rgba(3, 12, 28, 0.2);
    --color-black-10: rgba(3, 12, 28, 0.1);
    --color-black-5: rgba(3, 12, 28, 0.05);

    --color-primary-light: #F7F9FB;
    --color-primary-blue: #E3F5FF;
    --color-primary-purple: #E5ECF6;
    --color-primary-semi-purple: rgba(229, 236, 246, 0.5);

    --color-secondary-green-a: #A1E3CB;
    --color-secondary-green-b: #BAEDBD;
    --color-secondary-green-c: #3FCA90;
    --color-secondary-red: #FF4747;
    --color-secondary-red-b: #FD7D7D;
    --color-secondary-yellow-a: #FFC555;
    --color-secondary-yellow-b: #FFE999;
    --color-secondary-blue-a: #A8C5DA;
    --color-secondary-blue-b: #E3F5FF;
    --color-secondary-btn: #4F68E8;

    /* Format */
    --format-lg-padding: 24px;
    --format-md-padding: 12px;
    --format-sm-padding: 8px;

    --format-sm-gap: 10px;
    --format-md-gap: 12px;
    --format-lg-gap: 24px;

    --format-lg-border-radius: 19px;
    --format-md-border-radius: 12px;
    --format-small-border-radius: 10px;
    --format-header-height: 96px;

    /* Font Style */
    --font-family: 'Roboto', sans-serif;
    --font-weight-bold: 700;
    --font-weight-regular: 400;
    --font-line-height: 135%;

    /* Font Sizes */
    --font-size-small: 10px;
    --font-size-content: 14px;
    --font-size-large-content: 18px;
    --font-size-title: 24px;

}