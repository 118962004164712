.days_attribute_body { //div
  display: flex;
  flex-direction: column;
  gap: var(--format-md-gap);
}

.days_attribute_row { //div
  display: flex;
  gap: var(--format-lg-gap);
}

.days_attribute_item { //div
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 70px;
}

.days_attribute_value { //label
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);

  color: var(--color-black);

  &[data-is-disabled='true'] {
    color: var(--color-grey);
  }
}
