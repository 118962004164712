.popup {
  position: absolute;
  right: 0;

  padding: 16px 32px 16px 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-md-border-radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: max-content;

  & a {
    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-content);
    color: var(--theme-color-black);

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
