.page {
  position: relative;
  width: 100%;
  height: 100vh;

  background-image: url('../../Images/authBackground.jpg');
  background-position: 0 100%;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
}

.logo_block {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  width: max-content;
}

.logo {
  width: 420px;
  height: 70px;
  background-image: url('../../Images/svg/Logo.svg');
  background-size: contain;
}

.stores {
  display: flex;
  gap: var(--format-md-gap);
}

.store {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  gap: var(--format-md-gap);
  border-radius: var(--format-md-border-radius);
  padding: 8px 24px;
}

.store_icon {
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;

  &.google {
    background-image: url('../../Images/svg/appstore-logo.svg');
  }

  &.appstore {
    background-image: url('../../Images/png/playmarket-icon.png');
  }
}

.store_text {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-grey);
  text-align: center;
}

.form_block {
  position: relative;
  z-index: 2;
}