.label {
  &[data-size='lg'] {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
}

.input {
  padding: var(--format-md-padding);
  background-color: transparent;
  width: 100%;

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-black);

  border: 1px solid var(--color-grey-semi);
  border-radius: var(--format-small-border-radius);
  box-sizing: border-box;

  &[data-in-error='true'] {
    border: 1px solid var(--color-secondary-red);
  }
}

.error_text {
  display: none;
  position: absolute;
  width: 100%;

  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);

  color: var(--color-secondary-red);

  &[data-in-error='true'] {
    display: block;
  }
}