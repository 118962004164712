.hidden_button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;

  width: 20px;
  height: 20px;
  object-fit: contain;

  cursor: pointer;

  color: var(--color-grey);

  &:hover {
    color: var(--color-black-80);
  }
}