.remove_button {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  color: var(--color-grey-semi);
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:hover {
    color: var(--color-grey);
  }
}