.basic_order_form_row { //div
  display: flex;
  align-items: center;
  gap: var(--format-md-gap);
}

.basic_order_form_label { //div
  width: 163px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
}

.basic_order_form_row_title { //span
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-content);
  color: var(--color-black);
}

.package_type_block { //div
  display: flex;
  gap: var(--format-md-gap);
  color:  var(--color-black);
}